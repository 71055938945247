<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Post-Lab 2: Lab Notebook</h2>

      <p class="mb-n3">
        a) The lab notebook should be filled out BEFORE you come to lab. Of the following choices,
        choose the ONE thing that does NOT have to be in your notebook at the beginning of the lab
        period.
      </p>

      <v-radio-group
        v-model="inputs.notNeededInAdvance"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsNotNeededInAdvance"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">b) If you make a mistake in your lab notebook:</p>

      <v-radio-group
        v-model="inputs.howToCorrectMistakes"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsHowToCorrectMistakes"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) After getting to class and listening to the TA's pre-lab lecture, you should include in
        your lab notebook any changes to the procedure or chemicals made by the TA.
      </p>

      <v-radio-group
        v-model="inputs.procedureCorrections"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsTF"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        d) Your lab notebook should include a Table of Contents (TOC) for about the first three
        pages. After making the TOC, all pages should be numbered sequentially with a pen.
      </p>

      <v-radio-group v-model="inputs.tableOfContents" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsTF"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        e) In your notebook, it is permissible to write with which of the following writing
        utensils? Select all that apply.
      </p>

      <p
        v-for="option in optionsAcceptableWritingUtensils"
        :key="'pt-5-' + option.value"
        class="mb-0 mt-0"
      >
        <v-checkbox
          v-model="inputs.acceptableWritingUtensils"
          :value="option.value"
          class="ml-6 mb-n4 mt-0"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'OleMissPostlab2Questions',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        notNeededInAdvance: null,
        howToCorrectMistakes: null,
        procedureCorrections: null,
        tableOfContents: null,
        acceptableWritingUtensils: [],
      },
      optionsNotNeededInAdvance: [
        {text: 'A summary of the procedure', value: 'procedure'},
        {
          text: 'The hazards and safety precautions of the chemicals you expect to use or be exposed to',
          value: 'hazardsPrecautions',
        },
        {
          text: 'Blank data tables ready to receive the data from the procedure',
          value: 'dataTables',
        },
        {text: 'A place set aside for observations', value: 'spaceForObservations'},
        {text: 'A place set aside for representative calculations', value: 'spaceForCalculations'},
        {text: 'Data from the experiment', value: 'data'},
      ],
      optionsHowToCorrectMistakes: [
        {
          text: 'make a line through it with a single pen line and initial the line',
          value: 'singleStrokeInitial',
        },
        {text: 'scribble it out', value: 'scribble'},
        {text: 'just erase it', value: 'erase'},
        {text: 'use white-out so it will look neat and professional', value: 'whiteOut'},
      ],
      optionsTF: [
        {text: '$\\text{True}$', value: 'true'},
        {text: '$\\text{False}$', value: 'false'},
      ],
      optionsAcceptableWritingUtensils: [
        {text: 'Blue Pen', value: 'bluePen'},
        {text: 'Black Pen', value: 'blackPen'},
        {text: 'Pencils', value: 'pencil'},
        {text: 'Colored Pencils', value: 'coloredPencils'},
        {
          text: 'Colored pens as emphasis and on drawings but not as main writing color',
          value: 'colorsForEmphasis',
        },
        {text: 'Highlighters as emphasis on drawings', value: 'highlightersForEmphasis'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
